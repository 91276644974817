/* ShareButtons style.css */

@media (prefers-color-scheme: dark) {
  .share-buttons button {
    color: white;
  }
}

.share-buttons {
  display: flex;
  background-color: transparent;
  justify-content: center;
  margin-top: 1rem;
}

.share-buttons button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.share-buttons button:hover {
  color: #007bff;
}
