html {
  scroll-behavior: smooth;
}

body {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    circle,
    rgba(255, 255, 255, 1) 0%,
    rgba(80, 233, 255, 1) 100%
  );
  padding-bottom: 1rem; /* Add space for the subscription bar */
}

@media (prefers-color-scheme: dark) {
  body {
    background: rgb(0, 0, 0);
    background: radial-gradient(
      circle,
      rgb(46, 46, 46) 0%,
      rgba(0, 0, 0, 1) 100%
    );
  }
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  a {
    color: #007bff;
  }

  .blog-wrap {
    color: white;
  }

  .blog-date {
    color: white;
  }

  .blog-goBack span {
    color: white;
  }
}

.img-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.cover-img {
  max-width: 50cap;
  height: 50cap;
  overflow: scroll;
  border-radius: 1rem;
  transition: all 0.3s ease;
}

.cover-img:hover {
  transform: scale(1.1);
}

.article-img {
  max-width: 99%;
  height: auto;
  border-radius: 1rem;
  overflow: scroll;
  transition: transform 0.3s ease;
  padding-bottom: 0.5rem;
}

.img-caption {
  padding: 0.5rem;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-size: 0.9rem;
  font-weight: 700;
  transition: transform 0.3s ease;
}

.img-caption:hover,
.article-img:hover {
  transform: scale(1.1);
}

.blog-wrap {
  max-width: 700px;
  margin: 0 auto;
  font-size: 1.2rem;
}

.blog-goBack {
  text-decoration: none;
  font-size: 0.9rem;
  font-weight: 700;
  margin: 0.2rem;
  display: block;
  position: fixed;
}

.blog-wrap header {
  text-align: center;
}

.blog-date {
  font-size: 0.8rem;
  font-weight: 500;
}

.blog-wrap img {
  width: 100%;
}

.blog-subCategory {
  display: flex;
  justify-content: center;
}

.blog-subCategory > div {
  margin: 1rem;
}

.blog-desc {
  padding: 0.3rem;
  margin-top: 0.3rem;
}

p {
  padding: 0.5rem;
  font-size: 1.4rem;
}

blockquote {
  padding: 0.5rem;
  font-size: 1.4rem;
  font-style: italic;
  background-color: #3c3c3c;
  border-left: 10px solid #ccc;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

.blog-desc b {
  font-weight: 700;
  font-size: medium;
}

.code {
  padding: 2%;
  background-color: black;
  color: white;
  max-width: 100%;
  overflow: auto;
}

.code-div {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

pre {
  max-width: 100%;
}

.blog-desc h1,
.blog-desc h2,
.blog-desc h3,
.blog-desc h4,
.blog-desc h5,
.blog-desc h6 {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.share-btn {
  display: flex;
  justify-content: center;
  background: #000;
  color: white;
  margin-bottom: auto;
  border-radius: 0.5rem;
  padding: 0.5rem;
}

.blog-tags {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
}

.author {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.blog-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  box-sizing: border-box;
  background-color: black;
  border-radius: 1cap;
  size: 0.3rem;
}

.blog-footer img {
  width: 4.5rem;
  height: 4.5rem;
  margin: 0.5rem;
}
