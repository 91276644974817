.privacy-policy {
  padding: 4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
