/* CSS file for the consent modal styling */
.consent-modal {
  /* Center the modal */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;

  /* Styling */
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 80vw;
  max-width: 500px;
  text-align: center;
}

.consent-modal h2 {
  margin-top: 0;
}

.consent-modal p {
  margin: 20px 0;
}

.consent-modal button {
  /* Basic button styling */
  padding: 10px 20px;
  background-color: white;
  color: blue;
  border: 1px solid #0056b3;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.consent-modal button:hover {
  /* When the button is hovered, change the color */
  background-color: #0056b3;
}
