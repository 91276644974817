// COLORS
$primary-color: #23f8ff;
$secondary-color: rgba(80, 233, 255, 1);

$white-color: #ffffff;

$dark-grey: #333333;
$light-grey: #797979;

$dark-blue-text: #272341; // For Headings

// FONT SIZE
$default-font-size: 1.6rem;
$big-font-size: 4rem;
$mid-font-size: 2.5rem;
