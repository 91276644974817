.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem 0;
  text-shadow: 1px 1px 2px rgba(0, 57, 213, 0.5);
  background-color: black;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  color: white;
  margin-top: 1rem;
}

.email-subscription {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}

.email-subscription form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.email-subscription input[type="email"] {
  padding: 10px;
  border: solid;
  border-color: white;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 16px;
}

.email-subscription button {
  background-color: darkblue;
  color: white;
  border: none;
  padding: 1rem;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.email-subscription button:hover {
  background-color: blue;
}

.thank-you-message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: 500;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.home-icons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-top: 2rem;
}

.home-icons:hover {
  color: #da0303;
}

a {
  text-decoration: none;
}

.links {
  display: flex;
  color: white;
  gap: 2rem;
  justify-content: center;
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {
  /* Styles for screens wider than 768px */
  .email-subscription {
    flex-direction: row;
    align-items: center;
    padding-top: 2rem;
  }
}
