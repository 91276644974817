@media (prefers-color-scheme: dark) {
  body {
    scroll-behavior: smooth;
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
}

.blogList-wrap {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 3rem;
  text-wrap: wrap;
}

@media (max-width: 768px) {
  .blogList-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .blogList-wrap {
    grid-template-columns: repeat(1, 1fr);
  }
}
