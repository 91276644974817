@media (prefers-color-scheme: dark) {
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
}

.blogItem-wrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 1rem;
}

.blogItem-cover {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 0.5rem;
}
.blogItem-wrap h3 {
  margin: 0.5rem 0 1rem 0;
  flex: 1;
}

.blogItem-desc {
  position: relative;
  max-height: 5rem;
  overflow: hidden;
  padding-right: 0.6rem;
  font-size: 0.8rem;
}

.blogItem-desc::before {
  position: absolute;
  content: "...";
  bottom: 0;
  right: 0;
}

.blogItem-wrap footer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.blogItem-link {
  text-decoration: none;
  transition: transform 0.3s ease-out;
}

.blogItem-link:hover {
  transform: translateY(-10px);
}

.blogItem-author {
  display: flex;
  align-items: center;
}

.blogItem-author img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.5rem;
  transition: transform 0.3s ease-out;
}

.blogItem-author img:hover {
  transform: scale(1.2);
}

.blogItem-author p {
  font-size: 0.6rem;
  font-weight: 600;
}

.date-desc {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.date-desc p {
  font-size: 0.6rem;
  font-weight: 600;
  padding: 1px;
  margin: 3px;
}
